<template>
    <div class = "listBox">
        <div class = "l_none" v-if = "list.length == 0">
            <p>暂无数据</p>
        </div>
        <ul>
            <li v-for = "obj,index in list">
                <div 
                    class = "l_main" 
                    @touchstart="touchStart(index)" 
                    @touchmove="touchMove(index)" 
                    @touchend="touchEnd(index)" 
                    ref = "l_main"
                    data-isleft = "0"
                >
                    <div class = "l_info">
                        <p class = "li_title">
                            <slot name="title" v-bind:prop="obj" v-bind:index="index"></slot>
                        </p>
                        <p>
                            <slot name="datetime" v-bind:prop="obj" v-bind:index="index"></slot>
                        </p>
                    </div>
                    <div class = "l_sign">
                        <p>
                            <slot name="status" v-bind:prop="obj" v-bind:index="index"></slot>
                        </p>
                    </div>
                    <div class = "l_right"></div>
                </div>
                <div class = "l_btn" ref = "l_btn">
                    <slot name="btn" v-bind:prop="obj" v-bind:index="index"></slot>
                </div>
            </li>
        </ul>
        
    </div>
</template>
<script>
    import {staticPath} from '@/lib/common'
    export default {
        props: {
            list: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                staticPath,
                startX: 0,   //触摸位置
                endX: 0,     //结束位置
                moveX: 0,   //滑动时的位置
                disX: 0,    //移动距离
                deleteSlider: '',//滑动时的效果,使用v-bind:style="deleteSlider"
            };
        },
        methods: {
            toDetail(id, navId) {
                const pid = encodeURIComponent(btoa(id));
                const navigationId = encodeURIComponent(btoa(navId));
                this.$router.push({
                    name: 'ActivityDetail',
                    params: {
                        id: pid
                    },
                    query: {
                        n: navigationId
                    }
                })
            },
            touchStart(index,ev){
                ev = ev || event;
                //tounches类数组，等于1时表示此时有只有一只手指在触摸屏幕
                if(ev.touches.length == 1){
                    // 记录开始位置
                    this.startX = ev.touches[0].clientX;
                }
            },
            touchMove(index,ev){
                ev = ev || event;
                //获取删除按钮的宽度，此宽度为滑块左滑的最大距离
                let wd = this.$refs.l_btn[index].clientWidth;
                let ifLeft = this.$refs.l_main[index].dataset.isleft;
                //console.log(ifLeft);
                if(ev.touches.length == 1) {
                    // 滑动时距离浏览器左侧实时距离
                    this.moveX = ev.touches[0].clientX
                    //起始位置减去 实时的滑动的距离，得到手指实时偏移距离
                    this.disX = this.startX - this.moveX;
                    //console.log(this.disX)
                    // 如果是向右滑动或者不滑动，不改变滑块的位置
                    if (this.disX < 0 || this.disX == 0) {
                        this.$refs.l_main[index].style = "transform:translate3d(0,0,0)";
                    } 
                    else if (this.disX > 0) {// 大于0，表示左滑了，此时滑块开始滑动 
                        //具体滑动距离我取的是 手指偏移距离*5。
                        if(ifLeft == 1)return;
                        this.$refs.l_main[index].style = "transform:translate3d(-" + this.disX + "px,0,0)";
                        // 最大也只能等于删除按钮宽度 
                        if (this.disX >= wd) {
                            this.$refs.l_main[index].style = "transform:translate3d(-" +wd+ "px,0,0)";
                        }
                    }
                }
            },
            touchEnd(index,ev){
                ev = ev || event;
                let wd = this.$refs.l_btn[index].clientWidth;
                
                if (ev.changedTouches.length == 1) {
                    let endX = ev.changedTouches[0].clientX;
                    this.disX = this.startX - endX;
                    //console.log(this.disX);
                    //如果距离小于删除按钮一半,强行回到起点
                    if (this.disX < (wd/2)) {
                        if(this.disX > 0 && this.$refs.l_main[index].dataset.isleft == "1")return;
                        this.$refs.l_main[index].dataset.isleft = 0;
                        this.$refs.l_main[index].style = "transform:translate3d(0,0,0)";
                    }else{
                        if(this.$refs.l_main[index].dataset.isleft == "1")return;
                        //大于一半 滑动到最大值
                        this.$refs.l_main[index].style = "transform:translate3d(-"+wd+ "px,0,0)";
                        this.$refs.l_main[index].dataset.isleft = 1;
                    }
                }
            },
        }
    };
</script>
<style lang="less" scoped>
    
.fontFn (@px, @attr: font-size) {
    @rem: (@px / 37.5);
    @{attr}: ~"@{rem}rem";
}

.listBox{
    display: none;
}

@media screen and (max-width: 750px) {
    .listBox{
        display: block;
        .l_none{
            background: #fff;
            padding: 10px;
            text-align: center;
            .fontFn(12);
            color: #333;
        }
        ul{
            margin-bottom: 30px;
            li{
                
                .fontFn(5,border-radius);
                margin-bottom: 10px;
                position: relative;
                overflow: hidden;
                .l_main{
                    background: #fff;
                    position: relative;
                    z-index: 100;
                    display: flex;
                    align-items: center;
                    .fontFn(84,height);
                    padding-left: 22px;
                    padding-right: 16px;
                    transition-duration: .2s;
                    .l_info{
                        flex-grow: 1;
                        flex:auto;
                        p{
                            color:#5A5A5A;
                            .fontFn(14);
                        }
                        .li_title{
                            color:#34254C;
                            .fontFn(16);
                            font-weight: bold;
                            padding-bottom: 10px;
                        }
                    }
                    .l_sign{
                        flex:none;
                        width: 21.4%;
                        p{
                            .fontFn(14);
                            color:#a7a7a7;
                        }
                        .red{
                            color:#DF1923;
                        }
                        .green{
                            color:#44CB99;
                        }
                    }
                    .l_right{
                        width: 18px;
                        height: 18px;
                        border-top:2px solid #B9B9B9;
                        border-right:2px solid #B9B9B9;
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                    }
                }
                .l_btn{
                    position: absolute;
                    top:0;
                    right: 0;
                    height: 100%;
                    background:#efefef;
                    span{
                        display: inline-block;
                        vertical-align: top;
                        .fontFn(14);
                        .fontFn(60,width);
                        .fontFn(84,height);
                        .fontFn(84,line-height);
                        text-align: center;
                        border-left: 1px solid #ddd;
                    }
                }
            }
        }
    }
}
   
</style>